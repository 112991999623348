/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { CheckIcon, ArchiveIcon } from '@heroicons/react/outline'
const metrics = [
    { id: 1, emphasis: 'Well maintained open / Close Warehouse.' },
    { id: 2, emphasis: '24/7 Secured with CCTV camera' },
    { id: 3, emphasis: 'Well-equipped and built in sprinkler system.' },
    { id: 4, emphasis: 'Facility for Stackable / Non Stackable Cargoes.' },
    { id: 5, emphasis: 'Bar coding Facilities.' },
    { id: 6, emphasis: 'Well advanced cargo tracking service.' },
    { id: 7, emphasis: 'Online facilities.' },
]
export default function Storage() {
    return (
        <div className="relative bg-gray-900">
            <div className="h-80 absolute inset-x-0 bottom-0 xl:top-0 xl:h-full">
                <div className="h-full w-full xl:grid xl:grid-cols-2">
                    <div className="h-full xl:relative xl:col-start-2">
                        <img
                            className="h-full w-full object-cover opacity-25 xl:absolute xl:inset-0"
                            src="https://images.unsplash.com/photo-1624008915317-cb3ad69b16ad?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
                            alt="People working on laptops"
                        />
                        <div
                            aria-hidden="true"
                            className="absolute inset-x-0 top-0 h-32 bg-gradient-to-b from-gray-900 xl:inset-y-0 xl:left-0 xl:h-full xl:w-32 xl:bg-gradient-to-r"
                        />
                    </div>
                </div>
            </div>
            <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 xl:grid xl:grid-cols-2 xl:grid-flow-col-dense xl:gap-x-8">
                <div className="relative pt-12 pb-64 sm:pt-24 sm:pb-64 xl:col-start-1 xl:pb-24">
                    <p className="mt-3 text-3xl font-extrabold text-white uppercase">
                        Storage Facility
                    </p>
                    <p className="mt-5 text-lg text-gray-300">
                        We are arranging an excellent third party well equipped warehouse facility for storage in Dubai. Client Can keep their cargo in our warehouse as long as they wish to keep in the warehouse and the cargo safety and security will be guaranteed during to the storage period.
                    </p>
                    <div className="mt-12 grid grid-cols-1 gap-y-9 gap-x-6 sm:grid-cols-2">
                        {metrics.map((item) => (

                            <div key={item.id} className="relative">
                                <dt>
                                    <ArchiveIcon className="absolute h-6 w-6 text-green-500" aria-hidden="true" />
                                    <p className="ml-9 text-base leading-6 font-normal text-white">{item.emphasis}</p>
                                </dt>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}
