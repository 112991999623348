/* This example requires Tailwind CSS v2.0+ */
import { CheckIcon } from '@heroicons/react/outline'

const features = [
    {
        name: 'Shipping Agency Representation',
    },
    {
        name: 'InternationalFreight Forwarding',
    },
    {
        name: 'Land Transport to all GCC/ME/EUROPE',
    },
    {
        name: 'Packaging & Removal/Relocation Services.',
    },
    {
        name: 'Project Management',
    },
    {
        name: 'Warehouse Management',
    },
    {
        name: 'Ship Supply Service',
    },
    {
        name: 'Onboard Surveys',
    },
    {
        name: 'Cross Stuffing-Operations',
    },
    {
        name: 'Cross Trade Shipping Services.',
    },
    {
        name: 'Customs Clearance & Delivery',
    },
    {
        name: 'Dry Docking Services.',
    },
    {
        name: 'Used Container Trading/Supply',
    },
    {
        name: 'Technical & Mechanical Assistance to the Vessel',
    },
    {
        name: 'Ship’s Spares handling Clearance & delivery on board',
    },
    {
        name: 'Perishable Shipment Harding',
    },
    {
        name: 'Oilfield Equipment’s Handling/Export/Import/Clearance & Delivery',
    },
]

export default function Service() {
    return (
        <div className="bg-gradient-to-b from-gray-100" id="service">
            <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
                <div className="max-w-3xl mx-auto text-center">
                    <h2 className="text-3xl font-extrabold text-gray-900 uppercase">Our Service</h2>
                    <p className="mt-4 text-lg text-gray-500">
                        With the Global Customers in mind <b>A2B TRADELORDS SHIPPING L.L.C (TLS)</b> has Created a Solution Centric Service Portfolio that is Comprehensive and Globally relevant. with In Depth Market Knowledge and Experience as a Shipping and Logistics Provider we Can assure our Customers Single Window Access.
                    </p>
                </div>
                <dl className="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-4 lg:gap-x-8">
                    {features.map((feature) => (
                        <div key={feature.name} className="relative">
                            <dt>
                                <CheckIcon className="absolute h-6 w-6 text-green-500" aria-hidden="true" />
                                <p className="ml-9 text-base leading-6 font-normal text-gray-900">{feature.name}</p>
                            </dt>
                        </div>
                    ))}
                </dl>
            </div>
        </div>
    )
}
