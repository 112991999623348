import Img1 from '../assets/gallery/1.jpeg'
import Img2 from '../assets/gallery/2.jpeg'
import Img3 from '../assets/gallery/3.jpeg'
import Img4 from '../assets/gallery/4.jpeg'
import Img5 from '../assets/gallery/5.jpeg'
import Img6 from '../assets/gallery/6.jpeg'
import Img7 from '../assets/gallery/7.jpeg'
import Img8 from '../assets/gallery/8.jpeg'
import Img9 from '../assets/gallery/9.jpeg'
import Img10 from '../assets/gallery/10.jpeg'
import Img11 from '../assets/gallery/11.jpeg'
import Img12 from '../assets/gallery/12.jpeg'
import Img13 from '../assets/gallery/13.jpeg'
import Img14 from '../assets/gallery/14.jpeg'
import Img15 from '../assets/gallery/15.jpeg'
import Img16 from '../assets/gallery/16.jpeg'
import Img17 from '../assets/gallery/17.jpeg'
const people = [
    { imageUrl: Img1, },
    { imageUrl: Img2, },
    { imageUrl: Img3, },
    { imageUrl: Img4, },
    { imageUrl: Img5, },
    { imageUrl: Img6, },
    { imageUrl: Img7, },
    { imageUrl: Img8, },
    { imageUrl: Img9, },
    { imageUrl: Img10, },
    { imageUrl: Img11, },
    { imageUrl: Img12, },
    { imageUrl: Img13, },
    { imageUrl: Img14, },
    { imageUrl: Img15, },
    { imageUrl: Img16, },
    // More people...
]
export default function Gallery() {
    return (
        <div className="bg-slate-50" id="gallery">
            <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24 text-center">
                <div className="space-y-12">
                    <div className="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
                        <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl uppercase">Gallery</h2>
                        <p className="text-xl text-gray-500">
                        </p>
                    </div>
                    <ul
                        role="list"
                        className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-3 lg:gap-x-8"
                    >
                        {people.map((person) => (
                            <li key={person.name}>
                                <div className="space-y-4">
                                    <div className="max-h-[288px]">
                                        <img className="block h-full w-full object-cover shadow-lg rounded-lg max-h-[288px]" src={person.imageUrl} alt="" />
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    )
}
