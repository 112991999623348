/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { CheckIcon, CloudIcon } from '@heroicons/react/outline'
const metrics = [
    { id: 1, emphasis: 'Door to Door Delivery.' },
    { id: 2, emphasis: 'Air Cargo Imports / Exports.' },
    { id: 3, emphasis: 'Air Chartering' },
    { id: 4, emphasis: 'Sea / Air - Air / Sea services.' },
    { id: 5, emphasis: 'Perishables and Dangerous Goods Handling.' },
    { id: 6, emphasis: 'Online Customs Clearance.' },
    { id: 7, emphasis: 'Expertise in Handling Oilﬁeld Equipments Globally.' },
    { id: 8, emphasis: 'Special projects over sized cargo and dangerous goods.' },
]
export default function Air() {
    return (
        <div className="relative bg-gray-900">
            <div className="h-80 absolute inset-x-0 bottom-0 xl:top-0 xl:h-full">
                <div className="h-full w-full xl:grid xl:grid-cols-2">
                    <div className="h-full xl:relative xl:col-start-2">
                        <img
                            className="h-full w-full object-cover opacity-25 xl:absolute xl:inset-0"
                            src="https://images.unsplash.com/photo-1571086291540-b137111fa1c7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80"
                            alt="People working on laptops"
                        />
                        <div
                            aria-hidden="true"
                            className="absolute inset-x-0 top-0 h-32 bg-gradient-to-b from-gray-900 xl:inset-y-0 xl:left-0 xl:h-full xl:w-32 xl:bg-gradient-to-r"
                        />
                    </div>
                </div>
            </div>
            <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 xl:grid xl:grid-cols-2 xl:grid-flow-col-dense xl:gap-x-8">
                <div className="relative pt-12 pb-64 sm:pt-24 sm:pb-64 xl:col-start-1 xl:pb-24">
                    <p className="mt-3 text-3xl font-extrabold text-white uppercase">
                        Air freight
                    </p>
                    <p className="mt-5 text-lg text-gray-300">
                        All Leading Air Lines Handling their Operations from Dubai as a Regional Hub in UAE . We Are Handling Air Cargo World Wide as Per The Customer’s Choice In the Most safe and timely / Cost Effective manner. Each Shipments is handled with care by our expert staff so that your goods reach you safely.
                    </p>
                    <div className="mt-12 grid grid-cols-1 gap-y-9 gap-x-6 sm:grid-cols-2">
                        {metrics.map((item) => (

                            <div key={item.id} className="relative">
                                <dt>
                                    <CloudIcon className="absolute h-6 w-6 text-green-500" aria-hidden="true" />
                                    <p className="ml-9 text-base leading-6 font-normal text-white">{item.emphasis}</p>
                                </dt>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}
