import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import {
    AnnotationIcon,
    ChatAlt2Icon,
    ChatAltIcon,
    DocumentReportIcon,
    HeartIcon,
    InboxIcon,
    MenuIcon,
    PencilAltIcon,
    QuestionMarkCircleIcon,
    ReplyIcon,
    SparklesIcon,
    TrashIcon,
    UsersIcon,
    XIcon,
} from '@heroicons/react/outline'
import Logo from '../assets/logo.png'
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/solid'
import { PhoneIcon, ChatIcon, MailIcon, MapIcon } from '@heroicons/react/outline'
import Spotlight from '../components/Spotlight'
import Service from '../components/Service'
import Air from '../components/Air'
import Sea from '../components/Sea'
import BulkProject from '../components/BulkProject'
import Land from '../components/Land'
import Storage from '../components/Storage'
import Gallery from '../components/Gallery'
const navigation = [
    { name: 'About', href: '#about' },
    { name: 'Service', href: '#service' },
    { name: 'Galley', href: '#gallery' },
    { name: 'Features', href: '#features' },
    { name: 'Company', href: '#footer' },
]
const footerNavigation = {
    companeis: [
        { name: 'A2b Tradeloards Shipping L.L.C (Dubai - UAE)' },
        { name: 'Tradeloards Impex Pvt. Ltd. (Kochin - India) ' },
        { name: 'Tradeloards Impex Sp.zo.o (Warsaw - Poland)' },
        { name: 'Tradelords Canada Limited' },
        { name: 'Tradeloards Sweets & Confectioneries (Warsaw - Poland)' },
        { name: "Tradeloards Medical Tourism (Warsaw - Poland)" },
        { name: "Tradeloards Facility Management (Lisbon - Portugal)" },
    ],
    support: [
        { name: 'Pricing', href: '#' },
        { name: 'Documentation', href: '#' },
        { name: 'Guides', href: '#' },
        { name: 'API Status', href: '#' },
    ],
    offices: [
        { name: 'Europe', href: '#' },
        { name: 'Middle East', href: '#' },
        { name: 'India', href: '#' },
        { name: 'Canada', href: '#' },
        { name: 'UK', href: '#' },
        { name: 'USA', href: '#' },
        { name: 'UAE', href: '#' },
    ],
    company: [
        { name: 'About', href: '#' },
        { name: 'Blog', href: '#' },
        { name: 'Jobs', href: '#' },
        { name: 'Press', href: '#' },
        { name: 'Partners', href: '#' },
    ],
    legal: [
        { name: 'Claim', href: '#' },
        { name: 'Privacy', href: '#' },
        { name: 'Terms', href: '#' },
    ],
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Home() {
    return (
        <div className="bg-white">
            <div className="relative pt-6 pb-6 sm:pb-6 bg-gray-900">
                <Popover>
                    <div className="max-w-7xl mx-auto px-4 sm:px-6">
                        <nav className="relative flex items-center justify-between sm:h-10 md:justify-center" aria-label="Global">
                            <div className="flex items-center flex-1 md:absolute md:inset-y-0 md:left-0">
                                <div className="flex items-center justify-between w-full md:w-auto">
                                    <div className="-mr-2 flex items-center md:hidden">
                                        <Popover.Button className="bg-gray-50 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                            <span className="sr-only">Open main menu</span>
                                            <MenuIcon className="h-6 w-6" aria-hidden="true" />
                                        </Popover.Button>
                                    </div>
                                </div>
                            </div>
                            <div className="hidden md:flex md:space-x-10">
                                {navigation.map((item) => (
                                    <a key={item.name} href={item.href} className="font-medium text-white hover:text-gray-500">
                                        {item.name}
                                    </a>
                                ))}
                            </div>
                        </nav>
                    </div>

                    <Transition
                        as={Fragment}
                        enter="duration-150 ease-out"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="duration-100 ease-in"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Popover.Panel
                            focus
                            className="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
                        >
                            <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
                                <div className="px-5 pt-4 flex items-center justify-between">
                                    <div>
                                        <img
                                            className="h-8 w-auto"
                                            src={Logo}
                                            alt=""
                                        />
                                    </div>
                                    <div className="-mr-2">
                                        <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                            <span className="sr-only">Close main menu</span>
                                            <XIcon className="h-6 w-6" aria-hidden="true" />
                                        </Popover.Button>
                                    </div>
                                </div>
                                <div className="px-2 pt-2 pb-3 space-y-1">
                                    {navigation.map((item) => (
                                        <a
                                            key={item.name}
                                            href={item.href}
                                            className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                                        >
                                            {item.name}
                                        </a>
                                    ))}
                                </div>
                            </div>
                        </Popover.Panel>
                    </Transition>
                </Popover>


            </div>
            <main>
                {/* Hero section */}

                <Spotlight />
                <Service />
                <Gallery />
                <div id="features">
                    <Air />
                    <Sea />
                    <BulkProject />
                    <Land />
                    <Storage />
                </div>

                {/* CTA Section */}
                <div className="bg-white">
                    <div className="max-w-4xl mx-auto py-16 px-4 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8 lg:flex lg:items-center lg:justify-between">
                        <h2 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                            <span className="block">Ready to get started?</span>
                            <span className="-mb-1 pb-1 block bg-gradient-to-r from-purple-600 to-indigo-600 bg-clip-text text-transparent">
                                Get in touch.
                            </span>
                        </h2>
                        <div className="mt-6 space-y-4 sm:space-y-0 sm:flex sm:space-x-5">
                            <a
                                href="mailto:info@a2btradelordshipping.com"
                                className="flex items-center justify-center bg-origin-border px-4 py-2 border border-transparent text-base font-medium rounded-md shadow bg-gradient-to-r from-teal-500 to-cyan-600 text-white font-medium hover:from-teal-600 hover:to-cyan-700"
                            >
                                Mail Us
                            </a>
                            <a
                                href="tel:+97145709026"
                                className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-indigo-800 bg-teal-50 hover:bg-teal-100"
                            >
                                Contact
                            </a>
                        </div>
                    </div>
                </div>
            </main>

            <footer className="bg-gray-50" aria-labelledby="footer-heading" id="footer">
                <h2 id="footer-heading" className="sr-only">
                    Footer
                </h2>
                <div className="max-w-7xl mx-auto pt-16 pb-8 px-4 sm:px-6 lg:pt-24 lg:px-8">
                    <div className="xl:grid xl:grid-cols-2 xl:gap-8">
                        <div className="grid grid-cols-2 gap-8 xl:col-span-2">
                            <div className="justify-center flex gap-20">
                                <div>
                                    <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase ">Group of Companies </h3>
                                    <ul role="list" className="mt-4 space-y-4">
                                        {footerNavigation.companeis.map((item) => (
                                            <li key={item.name}>
                                                <dt className="text-base text-gray-500 hover:text-gray-900">
                                                    {item.name}
                                                </dt>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <div className="md:grid md:grid-cols-2 md:gap-8">
                                <div>
                                    <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">Associate Office</h3>
                                    <ul role="list" className="mt-4 space-y-4">
                                        {footerNavigation.offices.map((item) => (
                                            <li key={item.name}>
                                                <dt className="text-base text-gray-500 hover:text-gray-900">
                                                    {item.name}
                                                </dt>
                                            </li>
                                        ))}
                                    </ul>



                                </div>
                                <div className="mt-12 md:mt-0">
                                    <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">Contact</h3>
                                    <ul role="list" className="mt-4 space-y-4">

                                        {/*<li>
                                            <PhoneIcon className="absolute h-6 w-6 text-gray-500" aria-hidden="true" />
                                            <a href="tel:+97145709026" className="ml-8 text-base text-gray-500 hover:text-gray-900">
                                                +971 45709026
                                            </a>
                                        </li>
                                        <li>
                                            <ChatIcon className="absolute h-6 w-6 text-gray-500" aria-hidden="true" />
                                            <a href="tel:+971588856418" className="ml-8 text-base text-gray-500 hover:text-gray-900">
                                                +971 588856418
                                            </a>
                                        </li>*/}
                                        <li className='mt-8'>
                                            <MailIcon className="absolute h-6 w-6 text-gray-500" aria-hidden="true" />
                                            <a href="mailto:info@a2btradelordshipping.com" className="ml-9 text-base text-purple-500 hover:text-gray-900">
                                                info@a2btradelordshipping.com
                                            </a>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-12 border-t border-gray-200 pt-8 md:flex md:items-center md:justify-center lg:mt-16">
                        <p className="mt-8 text-base text-gray-400 md:mt-0 md:order-1">
                            &copy; 2022 TradeLords Group. All rights reserved.
                        </p>
                    </div>
                </div>
            </footer >
        </div >
    )
}
