/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { CheckIcon, TruckIcon } from '@heroicons/react/outline'
const metrics = [
    { id: 1, emphasis: 'Out of Gauge and Odd size cargo can move within UAE and all GCC and Europe.' },
    { id: 2, emphasis: 'Full Truck load(FTL) and less than truck load and LTL(Less Truck Load).' },
    { id: 3, emphasis: 'Container transportation.' },
    { id: 4, emphasis: 'International and local transport services.' },
]
export default function Land() {
    return (
        <div className="relative bg-gray-900">
            <div className="h-80 absolute inset-x-0 bottom-0 xl:top-0 xl:h-full">
                <div className="h-full w-full xl:grid xl:grid-cols-2">
                    <div className="h-full xl:relative xl:col-start-1">
                        <img
                            className="h-full w-full object-cover opacity-25 xl:absolute xl:inset-0"
                            src="https://images.unsplash.com/photo-1591419478162-a4dd21b7ec0a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
                            alt="People working on laptops"
                        />
                        <div
                            aria-hidden="true"
                            className="absolute inset-y-0 h-32 bg-gradient-to-b from-gray-900 xl:inset-y-0 xl:right-0 xl:h-full xl:w-32 xl:bg-gradient-to-l"
                        />
                    </div>
                </div>
            </div>
            <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 xl:grid xl:grid-cols-2 xl:grid-flow-col-dense xl:gap-x-8">
                <div className="ml-10 relative pt-12 pb-64 sm:pt-24 sm:pb-64 xl:col-start-2 xl:pb-24">
                    <p className="mt-3 text-3xl font-extrabold text-white uppercase">
                        Land Transportation
                    </p>
                    <p className="mt-5 text-lg text-gray-300">
                        A2B TRADELORDS SHIPPING L.L.C (TLS) Offers a Divers Range of Transportation Services from Dubai to All GCC/ME Countries and Europe . All Type of Cargo Can be Delivered By road to All GCC Countries and Europe by using different Type of Vehicles Such As 40 Ft & 50 Ft Trailers, Low Bed Trailers and 24 Meter Trailers & Refer Trucks and multi wheels trucks.
                    </p>
                    <div className="mt-12 grid grid-cols-1 gap-y-9 gap-x-6 sm:grid-cols-2">
                        {metrics.map((item) => (

                            <div key={item.id} className="relative">
                                <dt>
                                    <TruckIcon className="absolute h-6 w-6 text-green-500" aria-hidden="true" />
                                    <p className="ml-9 text-base leading-6 font-normal text-white">{item.emphasis}</p>
                                </dt>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}
