/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { CheckIcon, GlobeIcon } from '@heroicons/react/outline'
const metrics = [
    { id: 1, emphasis: 'Sea Freight Import / Export.' },
    { id: 2, emphasis: 'LCL and FCL Consolidation.' },
    { id: 3, emphasis: ' Clearance and Delivery.' },
    { id: 4, emphasis: 'Sea / Air - Air / Sea services.' },
    { id: 5, emphasis: 'Door to Door Services.' },
    { id: 6, emphasis: 'Ex-factory Export Loading Survey. ' },
    { id: 7, emphasis: 'Global Agency Network.' },
    { id: 8, emphasis: 'Cross Trade Shipments handling.' },
    { id: 9, emphasis: 'Fixed Cut-off’s with assured sailing.' },
]
export default function Sea() {
    return (
        <div className="relative bg-gray-900">
            <div className="h-80 absolute inset-x-0 bottom-0 xl:top-0 xl:h-full">
                <div className="h-full w-full xl:grid xl:grid-cols-2">
                    <div className="h-full xl:relative xl:col-start-1">
                        <img
                            className="h-full w-full object-cover opacity-25 xl:absolute xl:inset-0"
                            src="https://images.unsplash.com/photo-1585713181935-d5f622cc2415?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1171&q=80"
                            alt="People working on laptops"
                        />
                        <div
                            aria-hidden="true"
                            className="absolute inset-y-0 top-0 h-32 bg-gradient-to-b from-gray-900 xl:inset-y-0 xl:right-0 xl:h-full xl:w-32 xl:bg-gradient-to-l"
                        />
                    </div>
                </div>
            </div>
            <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 xl:grid xl:grid-cols-2 xl:grid-flow-col-dense xl:gap-x-8">
                <div className="ml-10 relative pt-12 pb-64 sm:pt-24 sm:pb-64 xl:col-start-2 xl:pb-24">
                    <p className="mt-3 text-3xl font-extrabold text-white uppercase">
                        Sea freight
                    </p>
                    <p className="mt-5 text-lg text-gray-300">
                        The most Economical Mode of Transport is Sea Freight and A2B TRADELORDS SHIPPING L.L.C (TLS) Utilizes both Conference and non Conference Vessels for Our Valuable Customers to get best Choice in terms of Cost effective and Services.
                    </p>
                    <div className="mt-12 grid grid-cols-1 gap-y-9 gap-x-6 sm:grid-cols-2">
                        {metrics.map((item) => (

                            <div key={item.id} className="relative">
                                <dt>
                                    <GlobeIcon className="absolute h-6 w-6 text-green-500" aria-hidden="true" />
                                    <p className="ml-9 text-base leading-6 font-normal text-white">{item.emphasis}</p>
                                </dt>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}
