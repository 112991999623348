/* This example requires Tailwind CSS v2.0+ */
import { PresentationChartBarIcon, StarIcon, SupportIcon } from '@heroicons/react/outline'
import Logo from '../assets/logo.png'
const supportLinks = [
    {
        name: 'The Company',
        href: '#',
        description:
            '<b> A2B TRADELORDS SHIPPING L.L.C (TLS) </b> is fast growing International freight forwarding company registered as a Limited Liability company in Dubai, United Arab Emirates, in accordance with The provisions of the UAE Commercial company Law.<b> A2B TRADELORDS SHIPPING L.L.C (TLS) </b> providing wide range of Shipping and Logistics solutions globally.',
        icon: StarIcon,
    },
    {
        name: 'The Management',
        href: '#',
        description:
            '<b> A2B TRADELORDS SHIPPING L.L.C (TLS) </b> A young and Dynamic Company engaged in International Transportation.Formed by a close knit group of logistics experts With years of expertise and Experience <b> A2B TRADELORDS SHIPPING L.L.C (TLS) </b> Controlled by well experienced Shipping and Logistics professionals.',
        icon: SupportIcon,
    },
    {
        name: 'Our Strength',
        href: '#',
        description:
            'Our young hardworking and experienced Professional staff can meet the ever growing demand from our valid customers through our Global Agency Network and our associated office in UAE/ ME / EUROPE / US / FAREEAST, UK, CANADA and all INDIAN destinations and Globally.',
        icon: PresentationChartBarIcon,
    },
]

export default function Spotlight() {
    return (
        <div className="bg-white" id="about">
            {/* Header */}
            <div className="relative pb-32 bg-gray-800">
                <div className="absolute inset-0">
                    <img
                        className="w-full h-full object-cover"
                        src="https://images.unsplash.com/photo-1590497008432-598f04441de8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1691&q=80"
                        alt=""
                    />
                    <div className="absolute inset-0 bg-gray-800 mix-blend-multiply" aria-hidden="true" />
                </div>
                <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
                    <div class="w-2/5 mb-10">
                        <img
                            className="object-contain"
                            src={Logo}
                            alt="People working on laptops"
                        />
                    </div>
                    <h1 className="text-4xl font-extrabold tracking-tight text-white md:text-5xl lg:text-6xl">A2B TRADELORDS SHIPPING L.L.C (TLS)</h1> <p className="max-w-3xl text-sm text-gray-300">A Division of Tradelords Group of Companies
                    </p>
                    <p className="mt-6 max-w-3xl text-xl text-gray-300">
                        Fast growing International freight forwarding company registered as a Limited Liability company in Dubai.
                    </p>
                </div>
            </div>

            {/* Overlapping cards */}
            <section
                className="-mt-32 max-w-7xl mx-auto relative z-10 pb-32 px-4 sm:px-6 lg:px-8"
                aria-labelledby="contact-heading"
            >
                <h2 className="sr-only" id="contact-heading">
                    Contact us
                </h2>
                <div className="grid grid-cols-1 gap-y-20 lg:grid-cols-3 lg:gap-y-0 lg:gap-x-8">
                    {supportLinks.map((link) => (
                        <div key={link.name} className="flex flex-col bg-white rounded-2xl shadow-xl">
                            <div className="flex-1 relative pt-16 px-6 pb-8 md:px-8">
                                <div className="absolute top-0 p-5 inline-block bg-indigo-600 rounded-xl shadow-lg transform -translate-y-1/2">
                                    <link.icon className="h-6 w-6 text-white" aria-hidden="true" />
                                </div>
                                <h3 className="text-xl font-medium text-gray-900 mb-2 uppercase">{link.name}</h3>
                                <span className="mt-4 text-base text-gray-500" dangerouslySetInnerHTML={{ __html: link.description }} />
                            </div>
                        </div>
                    ))}
                </div>
            </section>
        </div>
    )
}
